<style lang="scss" scoped>
.tolttip-message {
  display: none !important;
}
</style>
<template>
  <div>
    <CRow>
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
      ></loading>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong>Entrega incompleta</strong>
          </CCardHeader>
          <CCardBody>
            <div class="form-group col-md-7">
              <label for="">Diario</label>
              <select
                class="form-control"
                name=""
                id=""
                @change="changeCategory($event)"
              >
                <option
                  v-for="item in arrCategory"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>

            <div class="form-group col-md-7">
              <label for="">Paquetes</label>
              <select
                class="form-control"
                name=""
                id=""
                @change="changeSubCategory($event)"
              >
                <option
                  v-for="item in arrSubCategory"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>

            <div class="form-group col-md-7">
              <label for="">Seleccione una suscripción</label>
              <select class="form-control" @change="changeSuscription($event)">
                <option value="">--Seleccionar--</option>
                <option
                  v-for="(item, index) in suscripciones"
                  :value="index + 1"
                  :key="item.id"
                >
                  {{ item.product }} - {{ item.id }} - {{ index + 1 }}
                </option>
              </select>
            </div>

            <!-- Desde aca la validacion -->
            <div v-if="showDos">
              <!-- Tipo dia de incidente -->
              <div class="form-group col-md-7">
                <label for="">Dia del incidente</label>
                <select
                  class="form-control"
                  name=""
                  id=""
                  @change="changeDay($event)"
                >
                  <option
                    v-for="item in arrDiaIncidente"
                    :value="item.id"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <!-- SESSION DE INFO DE SUSCRIPCION -->
            <div id="hoy" class="form-group col-md-7">
              <label>{{ nameSuscription }}</label>
              <div v-if="showTres">
                <select class="form-control" @change="changeFecha($event)">
                  <option value="">--Seleccionar--</option>
                  <option
                    v-for="(item, index) in arrFechasPasadas"
                    :value="index + 1"
                    :key="item.id"
                  >
                    {{ item }}
                  </option>
                </select>
                <p v-if="showTres" style="color: red">
                  *Se le recuerda que puede hacer un reclamo dentro los ultimos
                  7 dias de su periodo de entrega.
                </p>
              </div>
            </div>
            <!-- Ultimo mensaje -->
            <div class="form-group col-md-7">
              <label>{{ nameSuscriptionDos }}</label>
            </div>
            <!--  <CCardHeader>
                {{ nameSuscription }}
                <div class="card-header-actions">
                  <a
                    href="https://coreui.io/vue/docs/components/card-components"
                    class="card-header-action"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                  </a>
                </div>
              </CCardHeader>
              <CCardBody class="p-4">
                <div
                  v-if="suscriptionSelected['productos']"
                  class="col-md-7 container__detalle"
                >
                  <p
                    v-if="
                      suscriptionSelected['pagomensual'] != '' &&
                      suscriptionSelected['pagomensual'] != null &&
                      suscriptionSelected['pagomensual'] != undefined
                    "
                  >
                    <strong>Pago: </strong>
                    {{ suscriptionSelected["pagomensual"] | currency }}
                  </p>
                  <p
                    v-if="
                      suscriptionSelected['periodo'] != '' &&
                      suscriptionSelected['periodo'] != null &&
                      suscriptionSelected['periodo'] != undefined
                    "
                  >
                    <strong>Periodo: </strong
                    >{{ suscriptionSelected["periodo"] }}
                  </p>
                  <p v-if="suscriptionSelected['diasentregadiario'].length > 0">
                    <strong>Días de entrega de diario: </strong
                    >{{ suscriptionSelected["diasentregadiario"] }}
                  </p>
                  <p
                    v-if="
                      suscriptionSelected['importepagopendiente'] != '' &&
                      suscriptionSelected['importepagopendiente'] != null &&
                      suscriptionSelected['importepagopendiente'] != undefined
                    "
                  >
                    <strong>Importe de pago pendiente: </strong
                    >{{
                      suscriptionSelected["importepagopendiente"] | currency
                    }}
                  </p>
                  <p
                    v-if="
                      suscriptionSelected['fechasiguienterenovacion'] != '' &&
                      suscriptionSelected['fechasiguienterenovacion'] != null &&
                      suscriptionSelected['fechasiguienterenovacion'] !=
                        undefined
                    "
                  >
                    <strong>Fecha de siguiente renovación: </strong
                    >{{
                      suscriptionSelected["fechasiguienterenovacion"].split(
                        " "
                      )[0]
                    }}
                  </p>
                  <p
                    v-if="
                      suscriptionSelected['fechaultimopagocancelado'] != '' &&
                      suscriptionSelected['fechaultimopagocancelado'] != null &&
                      suscriptionSelected['fechaultimopagocancelado'] !=
                        undefined
                    "
                  >
                    <strong>Fecha de último pago cancelado: </strong
                    >{{
                      suscriptionSelected["fechaultimopagocancelado"].split(
                        " "
                      )[0]
                    }}
                  </p>
                  <p
                    v-if="
                      suscriptionSelected['importeultimopagocancelado'] != '' &&
                      suscriptionSelected['importeultimopagocancelado'] !=
                        null &&
                      suscriptionSelected['importeultimopagocancelado'] !=
                        undefined
                    "
                  >
                    <strong>Importe del último pago cancelado: </strong
                    >{{
                      suscriptionSelected["importeultimopagocancelado"]
                        | currency
                    }}
                  </p>
                </div>
              </CCardBody> -->
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <img class="avatar__alicia" src="../../assets/img/nueva_alicia_pages_all.png" />
    <img class="avatar__alicia__mobile" src="../../assets/img/nueva_alicia_pages_all.png" />
  </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Cards",
  data: function () {
    return {
      suscripciones: [],
      arrFechasPasadas: [],
      nameSuscription: "",
      nameSuscriptionDos: "",
      suscriptionSelected: {},
      categoryId: "",
      subCategoryId: "",
      selectedCategoria: "0",
      indiceSuscription: "",
      diaEscogido: "",
      nombreSuscriptor : "",
      idSusp: "",
      show: true,
      showDos: false,
      showTres: false,
      isCollapsed: true,
      isLoading: false,
      fullPage: true,
      arrCategory: [
        { id: "", name: "--Seleccionar--" },
        { id: "1", name: "El Comercio" },
        { id: "2", name: "Gestión" },
      ],
      arrDiaIncidente: [
        { id: "", name: "--Seleccionar--" },
        { id: "1", name: "Hoy" },
        { id: "2", name: "Fechas Pasadas" },
      ],
      arrSubCategory: [{ id: "", name: "--Seleccionar--" }],
      /* hoy */

      /* Fechas pasadas */
    };
  },
  components: {
    Loading,
  },
  methods: {
    changeCategory(event) {
      let vm = this;
      let id = event.target.value;
      vm.categoryId = id;
      vm.isLoading = true;

      if (id == "") {
        vm.arrSubCategory = [{ id: "", name: "--Seleccionar--" }];
      } else if (id == "1") {
        vm.arrSubCategory = [
          { id: "", name: "--Seleccionar--" },
          { id: "1", name: "El Comercio" },
          { id: "2", name: "El Comercio Impreso + Digital" },
          { id: "3", name: "El Comercio Digital" },
        ];
      } else if (id == "2") {
        vm.arrSubCategory = [
          { id: "", name: "--Seleccionar--" },
          { id: "4", name: "Gestión" },
          { id: "5", name: "Gestión Impreso + Digital" },
          { id: "6", name: "Gestión Digital" },
        ];
      }
      vm.suscripciones = [];
      vm.suscriptionSelected = {};
      vm.isLoading = false;
      vm.nameSuscription = "";

      vm.isLoading = false;
    },
    changeSubCategory(event) {
      let vm = this;
      let id = event.target.value;
      vm.subCategoryId = id;
      vm.isLoading = true;

      vm.suscripciones = [];
      vm.suscriptionSelected = {};
      vm.nameSuscription = "" /* Aún no ha seleccionado una suscripción */;

      if (id == "") {
        vm.isLoading = false;
      } else {
        let data = {
          nro_documento: localStorage.getItem("numeroDocumento"),
          tipo_documento: localStorage.getItem("tipoDocumento"),
          categoria: vm.categoryId,
          subcategoria: id,
          canal: "7",
        };

        axios
          .post(`${process.env.VUE_APP_ROOT_API}/listSuscripciones`, data)
          .then(function (res) {
            vm.isLoading = false;
            if (res.data.data.code == "1") {
              vm.suscripciones = res.data.data.landing;
              vm.idSusp = vm.suscripciones[0].id;
            } else {
              vm.isLoading = false;
              vm.$fire({
                title: "Información",
                text: "No tiene suscripciones en este paquete.",
                type: "info",
                timer: 4000,
              }).then((r) => {});
            }
          })
          .catch((error) => {
            vm.isLoading = false;
            vm.$fire({
              title: "Error",
              text: "No tiene suscripciones en este paquete.",
              type: "error",
              timer: 4000,
            }).then((r) => {});
          });
      }
    },
    changeSuscription(event) {
      let vm = this;
      let index = event.target.value;
      vm.indiceSuscription = index;
      vm.isLoading = true;
      vm.suscriptionSelected = {};
      vm.nameSuscription = "";
      if (index == null) {
        vm.isLoading = false;
      } else {
        //Aca comienza alguna validacionque se querria
        let data = {
          position_id: index,
          document_number: localStorage.getItem("numeroDocumento"),
          document_type: localStorage.getItem("tipoDocumento"),
          platformOrigin: "Landing",
        };

        axios
          .post(`${process.env.VUE_APP_ROOT_API}/validar-deuda`, data)
          .then(function (res) {
            vm.nombreSuscriptor = res.data.data.name;
            vm.isLoading = false;
            vm.suscriptionSelected = res.data.data;
            //console.log(vm.suscriptionSelected);

            if (vm.suscriptionSelected.code == 1) {
              vm.isLoading = false;
              vm.showDos = true;
              vm.$fire({
                title: "Información",
                text: `${vm.suscriptionSelected.name}, escoge el día del incidente `,
                type: "info",
                timer: 2000,
              }).then((r) => {});
            } else {
              vm.isLoading = false;
              vm.showDos = false;
              vm.$fire({
                title: "Información",
                text: `${vm.suscriptionSelected.name}, actualmente tienes una deuda de S/. ${vm.suscriptionSelected.deuda}, por ello no se generó el reparto de tu diario..`,
                type: "info",
                timer: 2000,
              }).then((r) => {});
            }
          });
      }
    },
    async changeDay(event) {
      let vm = this;
      let index = vm.indiceSuscription;
      /* HOY */
      if (event.target.value == "1") {
        vm.showTres = false;
        vm.nameSuscriptionDos = "";
        let data = {
          document_number: localStorage.getItem("numeroDocumento"),
          document_type: localStorage.getItem("tipoDocumento"),
          position_id: index,
          complaintType: "3",
          submotiveType: "3",
          platformOrigin: "Landing",
        };
        let response = await axios
          .post(`${process.env.VUE_APP_ROOT_API}/validar-pde`, data)
          .then(function (res) {
            vm.isLoading = false;
            return res.data.data;
          });

        if (response.code == 1) {
           vm.$fire({
                title: "Información",
                text: `${vm.nombreSuscriptor}, lamento lo ocurrido. Se ha reportado el inconveniente al área encargada.\nPor la situación ocasionada, la entrega no será debitada y el saldo a favor se aplicará en tu siguiente renovación.`,
                type: "info",
                timer: 3000,
              }).then((r) => {});
        } else if (response.code == 3) {
          vm.$fire({
                title: "Ops",
                text: `${vm.nombreSuscriptor}, el día de reclamo no corresponde con la suscripción contratada.`,
                type: "info",
                timer: 2000,
              }).then((r) => {});
          
        } else {
           vm.$fire({
                title: "Ops",
                text: `${vm.nombreSuscriptor}, verifico que tienes una solicitud en proceso.`,
                type: "info",
                timer: 2000,
              }).then((r) => {});

        }
        //console.log(vm.idSusp); el id del pakete escogido
        //validar todos los codes de la respuesta 1,2,3,4
      } else {
        /* Fechas pasadas */
        let data = {
          document_number: localStorage.getItem("numeroDocumento"),
          document_type: localStorage.getItem("tipoDocumento"),
          position_id: index,
          channel: "1",
        };
        let response = await axios
          .post(`${process.env.VUE_APP_ROOT_API}/listpdesuscripcion`, data)
          .then(function (res) {
            vm.isLoading = false;
            return res.data.data;
          });
        //console.log(response);

        if (response.code == 1) {
          vm.showTres = true;
          vm.nameSuscription = `${response.message} *`;
          vm.arrFechasPasadas = response.listPde.split("\n");
          vm.arrFechasPasadas.pop();
        } else {
          /* Aparentemenmte nunca entrara aca */
          vm.showTres = false;
          vm.nameSuscription = `${response.message}`;
        }
      }
    },
    async changeFecha(event) {
      let vm = this;
      let index = vm.indiceSuscription;
      vm.diaEscogido = event.target.value;

      let data = {
        document_number: localStorage.getItem("numeroDocumento"),
        document_type: localStorage.getItem("tipoDocumento"),
        complaintType: "3",
        positionId: index,
        listDates: vm.diaEscogido,
        platformOrigin: "Landing",
      };
      let response = await axios
        .post(`${process.env.VUE_APP_ROOT_API}/detailpdesuscripcion`, data)
        .then(function (res) {
          vm.isLoading = false;
          return res.data.data;
        });
      if (response.code == 1) {
        vm.$fire({
          title: `Se generó una nota de crédito`,
          text: `${vm.nombreSuscriptor}, identifico que hubo un error en el proceso de reparto. \nPor este motivo, se está generando una nota de crédito a tu favor que será aplicada en la siguiente renovación.`,
          icon: "success",
          timer: 5000,
        }).then((r) => {});
        vm.nameSuscriptionDos = `${response.message}`;
      } else if (response.code == 2) {
        vm.$fire({
          title: `Ops`,
          text: `Cuentas con una solicitud de reclamo pendiente.`,
          type: "info",
          timer: 3000,
        }).then((r) => {});
       
      } else {
           vm.$fire({
          title: `${response.message}`,
          type: "info",
          timer: 2000,
        }).then((r) => {});
      
      }
    },
  },
  filters: {
    currency(value) {
      return `S/ ${value}`;
    },
  },
  mounted() {
    let vm = this;
    axios.interceptors.request.use(
      (config) => {
        vm.isLoading = true;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    $("#pgb-container").hide();
  },
};
</script>
